import React from "react"
import Layout from "../../components/Layout"
import styled from "styled-components"
import RequestDemoForm from "../../components/individualProductPage/RequestDemoForm"
import StealBackground from "../../components/individualProductPage/images/steal-background.jpg"
import SpecBarMachineImage from "../../components/individualProductPage/images/mini-hd-25c-cylindrical-profile.56.png"
import UsaBackground from "../../components/individualProductPage/images/handmade-in-usa-banner-mini-hd.jpg"
import MachineOverviewImage from "../../components/individualProductPage/images/mini-hd-machineoverview.jpg"
import HeavyDutyBackground from "../../components/individualProductPage/images/heavy-duty-banner-mini-hd.jpg"
import MachineDimensionImage from "../../components/individualProductPage/images/mini-hd-machine-cad-drawings-size-top.jpg"

//Thumbnail Images
import CylindricalThumb1Image from "../../components/individualProductPage/images/mini-hd-25c_0001_mini-hd-25c-1.6.png"
import CylindricalThumb2Image from "../../components/individualProductPage/images/mini-hd-25c_0002_mini-hd-25c-1.7.png"
import CylindricalThumb3Image from "../../components/individualProductPage/images/mini-hd-25c_0003_mini-hd-25c-1.8.png"
import CylindricalThumb4Image from "../../components/individualProductPage/images/mini-hd-25c_0004_mini-hd-25c-1.9.png"
import CylindricalThumb5Image from "../../components/individualProductPage/images/mini-hd-25c_0000_mini-hd-25c-1.5.png"
import DiskThumb1 from "../../components/individualProductPage/images/mini-hd-disk-thumb1.jpg"
import DiskThumb2 from "../../components/individualProductPage/images/mini-hd-disk-thumb2.jpg"
import DiskThumb3 from "../../components/individualProductPage/images/mini-hd-disk-thumb3.jpg"
import DiskThumb4 from "../../components/individualProductPage/images/mini-hd-disk-thumb4.jpg"
import DiskThumb5 from "../../components/individualProductPage/images/mini-hd-disk-thumb5.jpg"
import OrbitalThumb1 from "../../components/individualProductPage/images/mini-hd-28O_0000_view-1.jpg"
import OrbitalThumb2 from "../../components/individualProductPage/images/mini-hd-28O_0001_view-2.jpg"
import OrbitalThumb3 from "../../components/individualProductPage/images/mini-hd-28O_0002_view-3.jpg"
import OrbitalThumb4 from "../../components/individualProductPage/images/mini-hd-28O_0003_view-4.jpg"
import OrbitalThumb5 from "../../components/individualProductPage/images/mini-hd-28O_0004_view-5.jpg"
//End Thumbnail Images
import '../product-page-styles.scss';

//Main Images
import CylindricalMain1Image from "../../components/individualProductPage/images/mini-hd-25c_0001_mini-hd-25c-1.6.png"
import CylindricalMain2Image from "../../components/individualProductPage/images/mini-hd-25c_0002_mini-hd-25c-1.7.png"
import CylindricalMain3Image from "../../components/individualProductPage/images/mini-hd-25c_0003_mini-hd-25c-1.8.png"
import CylindricalMain4Image from "../../components/individualProductPage/images/mini-hd-25c_0004_mini-hd-25c-1.9.png"
import CylindricalMain5Image from "../../components/individualProductPage/images/mini-hd-25c_0000_mini-hd-25c-1.5.png"
import DiskMain1Image from "../../components/individualProductPage/images/mini-hd-26d-1.png"
import DiskMain2Image from "../../components/individualProductPage/images/mini-hd-26d-4.png"
import DiskMain3Image from "../../components/individualProductPage/images/mini-hd-26d-3.png"
import DiskMain4Image from "../../components/individualProductPage/images/mini-hd-26d-2.png"
import DiskMain5Image from "../../components/individualProductPage/images/mini-hd-26d-5.png"

import OrbitalMain1Image from "../../components/individualProductPage/images/mini-hd-28O_0000_view-1.jpg"
import OrbitalMain2Image from "../../components/individualProductPage/images/mini-hd-28O_0001_view-2.jpg"
import OrbitalMain3Image from "../../components/individualProductPage/images/mini-hd-28O_0002_view-3.jpg"
import OrbitalMain4Image from "../../components/individualProductPage/images/mini-hd-28O_0003_view-4.jpg"
import OrbitalMain5Image from "../../components/individualProductPage/images/mini-hd-28O_0004_view-5.jpg"

import MediaTile from "../../components/individualProductPage/MediaTile"
import MainSpecBar from "../../components/individualProductPage/FeaturedProduct/MainSpecBar"
import MadeInUSA from "../../components/individualProductPage/MadeInUSA"
import SectionTitle from "../../components/common/SectionTitle"
import MachineOverview from "../../components/individualProductPage/MachineOverview"
import HeavyDuty from "../../components/individualProductPage/HeavyDuty"
import MachineDimensions from "../../components/individualProductPage/MachineDimensions"

import ControllerImage from "../../images/choose-your-controller-v2.jpg"

import { OptionsBar } from "../../components/individualProductPage/FeaturedProduct/FeaturedProductStyles/OptionsBar"
//End Main Images

import MiniHdOptions from "../../components/individualProductPage/MiniHdOptions"
import SEO from "../../components/SEO"
import Head from "../../components/Head"
import IndividualMachine from "../../components/individualProductPage/individualMachine"

const MediaTilesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1400px;
  margin: 0 auto;

  @media (max-width: 840px) {
    max-width: 650px;
  }
`
const ControllerImageContainer = styled.div`
  max-width: 1400px;
  margin: 8rem auto;
  text-align: center;
  color: red;

  h1 {
    margin: 3rem 0;
    font-size: 3em;
  }

  img {
    width: 100%;
    height: auto;
  }
`
const SizeBtnContainer = styled.div`
  max-width: 500px;
  margin: 0 auto;
  text-align: center;
`

const MiniHDProductPage = props => {
  return (
    <>
      <SEO title="Mini-HD" />
      <Head title="Mini-HD" />
      <Layout>
        <IndividualMachine
          cylindricalThumb1={CylindricalThumb1Image}
          cylindricalThumb2={CylindricalThumb2Image}
          cylindricalThumb3={CylindricalThumb3Image}
          cylindricalThumb4={CylindricalThumb4Image}
          cylindricalThumb5={CylindricalThumb5Image}
          cylindricalMain1={CylindricalMain1Image}
          cylindricalMain2={CylindricalMain2Image}
          cylindricalMain3={CylindricalMain3Image}
          cylindricalMain4={CylindricalMain4Image}
          cylindricalMain5={CylindricalMain5Image}
          diskThumb1={DiskThumb1}
          diskThumb2={DiskThumb2}
          diskThumb3={DiskThumb3}
          diskThumb4={DiskThumb4}
          diskThumb5={DiskThumb5}
          diskMain1={DiskMain1Image}
          diskMain2={DiskMain2Image}
          diskMain3={DiskMain3Image}
          diskMain4={DiskMain4Image}
          diskMain5={DiskMain5Image}
          orbitalThumb1={OrbitalThumb1}
          orbitalThumb2={OrbitalThumb2}
          orbitalThumb3={OrbitalThumb3}
          orbitalThumb4={OrbitalThumb4}
          orbitalThumb5={OrbitalThumb5}
          orbitalMain1={OrbitalMain1Image}
          orbitalMain2={OrbitalMain2Image}
          orbitalMain3={OrbitalMain3Image}
          orbitalMain4={OrbitalMain4Image}
          orbitalMain5={OrbitalMain5Image}
          brochureLink="https://www.dropbox.com/s/isekh8ont113wxr/FC-V2-WALK-BEHIND-SM.pdf?dl=0"
          techLink="/FC-V2-WALK-BEHIND-specs.pdf"
          partsLink="https://www.dropbox.com/s/j08z9mxcolidnmb/FC-MINI-HD-v2-P-EN.pdf?dl=0"
          opLink="https://www.dropbox.com/s/mnz7nzrpr9fa9bb/FC-walk-behind-v2-OP-EN.pdf?dl=0?dl=0"
          title="Mini-HD Walk Behind Floor Scrubber"
          textContent="Versatility best describes the Mini-HD battery powered floor scrubber.  Large enough to be highly productive yet small enough to maneuver easily thought doorways and in confined areas. Add the ZerO3 On-Demand Aqueous Ozone chemical free cleaning system and clean better, chemical free leaving your facility shining and smelling fresh."
          mobileTitle="Mini-HD Walk Behind Floor Scrubber"
          pageUrl=""
          specifications="Cleaning Path: 25-29 | Run Time: Up To 3.5 Hours | Tank Capacity: Sol: 21 Gal, Rec: 23 Gal"
          videoSource="https://www.youtube.com/embed/NaGoDJxcSGM"
          upLink="/walk-behind-floor-scrubbers/mag-hd"
          machineName="Mini-HD"
          pmLink="/Walk-Behind-V2-PM.pdf"
          downLink="/walk-behind-floor-scrubbers/micro-hd"
          mtLink="https://www.dropbox.com/s/sx03160k2ppabfl/daily-weekly-maintenance-premium-v21306.pdf?dl=0"

        />
        <div className="tiles-container">
          <MediaTile
            title="Why FactoryCat ?"
            subTitle="Walk Behind Floor Scrubbers"
            link="/v2-you-be-the-judge.pdf"
          />
          <MediaTile
            title="Videos"
            subTitle="Walk Behind Floor Scrubbers"
            link="/videos"
          />
          <MediaTile
            link="/images#walkie-scrubbers"
            title="Images"
            subTitle="Walk Behind Floor Scrubbers"
          />
          <MediaTile
            title="Applications"
            subTitle="Walk Behind Floor Scrubbers"
            link="/applications"
          />
        </div>
        <MainSpecBar
          bgImage={StealBackground}
          machineImage={SpecBarMachineImage}
          myColor="white"
          machineName="Mini-HD"
          category="Walk Behind Floor Scrubber"
          path='25 - 29"'
          path2="(63.5 - 73.7 cm)"
          runTime="Up To 3.5 Hours"
          solution="Solution: 21 Gal"
          solutionLiter="(79.5L)"
          recovery="Recovery: 23 Gal"
          recoveryLiter="(87L)"
        />
        <ControllerImageContainer>
          <h1>Choose Your Controller</h1>
          <img src={ControllerImage} alt="" />
        </ControllerImageContainer>
        <MadeInUSA bgImage={UsaBackground} />
        <div className="spec-title-wrapper">
        <SectionTitle title="Machine Overview" />
        </div>
        <MachineOverview image={MachineOverviewImage} />
        <HeavyDuty
          bgImage={HeavyDutyBackground}
          innerText="HEAVY DUTY CLEANING"
        />
        <MachineDimensions
          image={MachineDimensionImage}
          sizeTitle="Size (L x W x H):"
          size='52" x 37" x 40'
          weightTitle="Weight (w/o batteries):"
          weight="393 - 443 lbs"
          voltageTitle="System Voltage:"
          voltage="24 VDC"
          stdBatteryTitle="Standard Battery:"
          battery="210 ah WET"
          powerTitle="Power:"
          power="0.75hp"
          tankTitle="Tank Material:"
          tank="Poly 3/8"
        />
        <OptionsBar>Options</OptionsBar>
        <MiniHdOptions />
        <div id="demo-form"></div>
        <div className="spec-title-wrapper">
        <SectionTitle title="Request a Demo" />
        </div>
        <RequestDemoForm image={CylindricalMain1Image} />
      </Layout>
    </>
  )
}

export default MiniHDProductPage
