import styled from "styled-components"

export const OptionsBar = styled.div`
  width: 100%;
  text-align: center;
  color: white;
  /* padding: 1.5rem; */
  background-color: #ed2024;
  font-size: 2em;
`
